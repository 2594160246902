import React from 'react';
import TextFormatter from '../../utils/TextFormatter';

export interface IVideoSection {
  showSection: boolean,
  title: string,
  description: {
    data: {
      description: string,
    }
  },
  youtubeUrl: string,
}

type VideoProps = {
  video: IVideoSection,
  className?: string,
}

const Video = ({video, className}: VideoProps) => {
  if (!video.showSection || !video.youtubeUrl) {
    return null;
  }

  const videoSectionDescription = TextFormatter.concatOrphans(video.description.data.description) || video.description.data.description;

  return (
    <section className={className ?? 'bg-white flex flex-col gap-5 items-center text-center justify-center pb-36'}>
      <h2 className={'text-4xl xl:text-5xl font-head text-black'}>{video.title}</h2>
      <p className={'text-base xl:text-lg text-neutrals-40'} dangerouslySetInnerHTML={{__html: videoSectionDescription}}/>
      <iframe className={'w-[320px] h-[240px] sm:w-[620px] sm:h-[480px] lg:w-[768px] lg:h-[576px] rounded-[20px]'} src={video.youtubeUrl}/>
    </section>
  )
}

export default Video;
