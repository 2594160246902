import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import TextFormatter from "../../../utils/TextFormatter";
import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";
import Scroller from "../../../utils/Scroller";

interface IHeroProps {
  title: string,
  image: GatsbyImageCustomType,
  seeMore?: () => void,
  green?: boolean | null,
}

const Hero = ({title, image, seeMore, green}: IHeroProps) => {
  let scrollToFeatureSection: () => void;

  if (!seeMore) {
    scrollToFeatureSection = () => Scroller.scrollTo('features-section', window.innerWidth < 1280 ? 95 : 100);
  }
  const formattedTitle = TextFormatter.concatOrphans(title) || title;
  return(
    <section className={'relative max-w-[1920px] ml-auto mr-auto'}>
      <div className={'w-full h-full absolute top-0 left-0 z-[-1]'}>
        <GatsbyImage loading={'eager'} className={'hero-background-img w-full h-full'} alt="" image={image.localFile.childImageSharp.gatsbyImageData} />
      </div>
      <div className={'flex flex-col w-full h-full justify-center'}>
        <div className={'flex flex-1 justify-center pb-20 pt-36 sm:pb-36 sm:pt-60 md:py-64 lg:py-96 px-5 sm:px-20 lg:px-36 flex-col'}>
          <h1 className={'font-head whitespace-pre-line text-3xl sm:text-4xl lg:text-5xl text-white xl:text-6xl mb-10 shrink-0 !leading-tight font-medium'} dangerouslySetInnerHTML={{__html: formattedTitle}}/>
          <button type="button" onClick={() => seeMore ? seeMore() : scrollToFeatureSection()} className={`mtr-btn sm:mtr-btn-large ${green ? `mtr-btn-[primary]` : 'mtr-btn-[secondary]'} w-fit`}>
            <span className={'uppercase'}>Zobacz więcej</span>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Hero;
