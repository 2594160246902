import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import Layout from "../../components/Layout";
import {IHeroSection} from "../../components/templates/hero/hero-section.interface";
import {IFeatureSection} from "../../components/templates/features-z-pattern/feature-section.interface";
import {ISpecialOfferSection} from "../../components/templates/special-offer/special-offer.interface";
import {IHelpSection} from "../../components/templates/help/help-section.interface";
import Hero from "../../components/templates/hero/Hero";
import FeaturesZPattern from "../../components/templates/features-z-pattern/FeaturesZPattern";
import SpecialOffer from "../../components/templates/special-offer/SpecialOffer";
import Help from "../../components/templates/help/Help";
import {SiteMetadata} from "../../types/site-metadata.type";
import {SiteMetadataConfigProps} from "../../types/metadata-config-props.type";
import DeviceSEO from "../../components/device/DeviceSEO";
import {PageContextType} from "../../types/page-context.type";
import Video, {IVideoSection} from '../../components/video/Video';

type FunctionalitiesProps = {
  strapiDeviceFunctionality: {
    hero: IHeroSection,
    properties: IFeatureSection[],
    specialOffer: ISpecialOfferSection,
    help: IHelpSection,
    siteMetadata: SiteMetadata,
    video: IVideoSection,
  }
} & SiteMetadataConfigProps

const Functionalities = ({data: {strapiDeviceFunctionality: {help, hero, specialOffer, properties, siteMetadata, video}}, pageContext}: PageProps<FunctionalitiesProps, PageContextType>) => {

  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <Hero title={hero.title} image={hero.image}/>
      <FeaturesZPattern features={properties} id={'features-section'} />
      <Video video={video}/>
      <SpecialOffer specialOffer={specialOffer} />
      <Help helpSection={help} />
    </Layout>
  )
}

export default Functionalities;

export const Head = (props: HeadProps<FunctionalitiesProps>) => {
  const path = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const siteMetadata = props.data.strapiDeviceFunctionality.siteMetadata;
  return (
    <DeviceSEO siteUrl={siteUrl} path={path} siteMetadata={siteMetadata} />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiDeviceFunctionality {
      video {
        description {
          data {
            description
          }
        }
        title
        youtubeUrl
        showSection
      }
      siteMetadata {
        crumbLabel
        title
        description
      }
      hero {
        title
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
      properties {
        title
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      specialOffer {
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        button {
          url
          label
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      help {
        title
        description
        email
        number
      }
    }
  }
`
