import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import {IFeatureSection} from "./feature-section.interface";
import './featuresZPattern.scss';
import TextFormatter from "../../../utils/TextFormatter";

type FeaturesZPatternProps = {
  features: IFeatureSection[],
  id: string,
  showSteps?: boolean,
  children?: React.ReactNode,
  green?: boolean | null,
}

const FeaturesZPattern = ({features, id, showSteps, children, green}: FeaturesZPatternProps) => {
  return (
    <section id={id}>
      {features.map((feature, index) => {
        const description = TextFormatter.concatOrphans(feature.description.data.description) || feature.description.data.description;
        const title = TextFormatter.concatOrphans(feature.title) || feature.title;
        const even = index % 2 === 0;
          return (
            <div key={index} className={`flex flex-col justify-center items-center bg-white ${green ? `even:bg-primary-60` : 'even:bg-tertiary-40'} w-full`}>
              <div className={`flex flex-col items-center ${even ? 'lg:flex-row' : 'lg:flex-row-reverse'} px-5 sm:px-20 xl:px-36 py-20 md:py-28 gap-y-11 gap-x-14 xl:gap-x-24 max-w-[1520px]`}>
                <div className={'flex flex-col lg:w-1/2'}>
                  {showSteps
                    ? (<div className={'flex flex-row gap-6 mb-10 items-center'}>
                        <div className={'flex shrink-0 justify-center items-center border-2 border-secondary-60 w-24 h-24 rounded-full'}>
                          <p className={`text-5xl font-head ${even ? 'text-black' : 'text-white'}`}>{index + 1}</p>
                        </div>
                        <div className={'flex flex-col gap-1.5 text-start'}>
                          <p className={'uppercase text-primary-60 text-xs lg:text-sm text-semibold'}>Krok NR {index + 1}</p>
                          <h2 className={`font-head text-bold text-xl lg:text-2xl xl:text-3xl ${even ? 'text-black' : 'text-white'}`} dangerouslySetInnerHTML={{__html: title}}/>
                        </div>
                      </div>)
                    : <h2 className={`font-head text-4xl xl:text-5xl mb-7 sm:mb-12 !leading-tight ${even ? 'text-black' : 'text-white'}`} dangerouslySetInnerHTML={{__html: title}}/>
                  }
                  <div className={`description-container ${green && !even ? ' description-container--green ' : ''} whitespace-pre-line lg:pr-9 text-lg ${even ? 'text-neutrals-40' : 'text-neutrals-80'}`} dangerouslySetInnerHTML={{__html: description}}/>
                </div>
                <div className={'flex w-full justify-center lg:w-1/2'}>
                  <GatsbyImage className={'rounded'} alt={feature.image.alternativeText} image={feature.image.localFile.childImageSharp.gatsbyImageData} />
                </div>
              </div>
            </div>
          )
      })}
      {children}
    </section>
  )
}

export default FeaturesZPattern;
