import React from "react";
import {SiteMetadata} from "../../types/site-metadata.type";
import StructuredData from '../../assets/structured-data';

type DeviceSEOProps = {
  siteUrl: string,
  path: string,
  siteMetadata: SiteMetadata
}

const DeviceSEO = ({siteMetadata, siteUrl, path}: DeviceSEOProps) => {
  const slugs = path.split('/');
  const {title, description, crumbLabel} = siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: 'Urządzenie',
          item: siteUrl + '/' + slugs[1] + '/'
        },
        {
          name: crumbLabel,
          item: siteUrl + '/' + slugs[1] + '/' + slugs[2] + '/'
        }
      ])}
    </>
  )
}

export default DeviceSEO;
