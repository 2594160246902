class Scroller {

  static scrollTo(id: string, offset = 0): void {
    const element = document.getElementById(id);

    if (!element) {
      throw Error(`There is no element with ${id} id`)
    }

    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })

    return;
  }
}

export default Scroller;
